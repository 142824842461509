
import icon_example from '../src/assets/images/icons/1.png';

import BogdanPhoto from '../src/assets/images/team_members/processed/Bogdan Micu_processed.jpg';
import RobiPhoto from '../src/assets/images/team_members/processed/RobiTacutu_processed.png';
import DmitriPhoto from '../src/assets/images/team_members/processed/profile-pic_Mitya.png';
import AntonPhoto from '../src/assets/images/team_members/processed/profile-pic_Anton.png';
import EugenPhoto from '../src/assets/images/team_members/processed/profile-pic_Eugen.png';
import SimonaPhoto from '../src/assets/images/team_members/processed/profile-pic_Simona.png';
import GabiPhoto from '../src/assets/images/team_members/processed/profile-pic_Gabi.png';
import MonicaPhoto from '../src/assets/images/team_members/processed/profile-pic_Monica.png';

export default {
    "pallette": {
        primary: "#5CDB95",
        primary_light: "#379683",
        secondary: "#05386B",
        secondary_light: "#8EE4AF",
        contrast: "#EDF5E1"
    },
    "SEO": {
        "title": "CellFabrik",
        "description": ""
    },
    "Header": {
        "menuItems": [
            {
                "path": "home",
                "label": "Home",
            },
            {
                "path": "about",
                "label": "About",
            },
            {
                "path": "team",
                "label": "Team",
            },
            // {
            //     "path": "advisory",
            //     "label": "Advisory Board"
            // },
            // {
            //      "path": "gitcoin",
            //      "label": "Gitcoin"
            // },
            {
                "path": "contact",
                "label": "Contact",
            },
        ]
    },
    "Banner": {
        "Motto": "Cost-efficient, sustainable and serum-free cultivated meat"  // "Developing personalized therapies for autoimmune diseases"
    },
    "About": {
        "title": "About us",
        "SystemsBioGroup_Link": "https://aging-research.group",
        "text1": "We are a startup biotech company based in Bucharest, Romania. Our team started as part of the ", 
        "text2": " and is composed of a mix of multidisciplinary scientists with strong expertise in molecular cell biology, proteomics, genome editing, bioinformatics, and systems biology. Our mission is to conduct basic and applied biotech research to develop innovative approaches for cultivated meat production. CellFabrik aims to sustainably produce high-quality cultivated food for pet and human consumption at affordable costs. Our company enjoys permanent links and collaborations with several high-profile institutes, universities, and hospitals."
    },
    "Team": {
        "section_title": "Cellfabrik Team",
        "section_description": "",
        "data": [
            {
                "id": 1,
                "icon": BogdanPhoto,
                "title": "Bogdan Micu",
                "role": "CEO, Business Development",
                "description": "Bogdan is an experienced entrepreneur in the fields of IT and Energy and was awarded Entrepreneur of the Year by PricewaterhouseCoopers (PwC). He has extensive experience in the growth and development of companies in both emerging and established markets, with a particular focus on the US market. Currently, Bogdan is steering the CellFabrik projects towards a successful outcome. He is also known for his role as an investor on the Romanian version of 'Shark Tank,' a television show dedicated to entrepreneurs, broadcasted by ProTV."
            },
            {
                "id": 2,
                "icon": RobiPhoto,
                "title": "Robi Tacutu, PhD",
                "role": "CSO, Administration, Scientific Management",
                "description": "Robi is a scientist and entrepreneur with over 15 years of experience in aging research, systems biology, bioinformatics, and molecular biology. His educational background includes both Computer Science (BSc) and Life/Medical Sciences (MSc/PhD). Following his PhD at Ben-Gurion University in Israel and a postdoctoral position at the University of Liverpool in the UK, Robi secured a 2 million EUR grant to establish a research group at the Institute of Biochemistry in Bucharest. He has authored scientific papers covering a broad range of topics, from aging to bioinformatics and machine learning. He remains actively involved in both academia and industry."
            },
            {
                "id": 3,
                "icon": DmitriPhoto,
                "title": "Dmitri Toren, PhD",
                "role": "CTO, Operations, Senior Researcher",
                "description": "Dmitri earned his PhD in Medical Sciences from Ben-Gurion University of the Negev, Israel. He has an extensive background in molecular biology, algae and mammalian cell culture, and animal models. With over a decade of experience in academic lecturing and mentoring, Dmitri has actively participated in academic grants, published multiple high-impact scientific papers, and served as a reviewer. In addition, he has applied his expertise in the industry, consulting for several years with the biomechanics startup MDGo (later SENSA)."
            },
            {
                "id": 4,
                "icon": AntonPhoto,
                "title": "Anton Kulaga",
                "role": "R&D, Bioinformatics, Innovation",
                "description": "Anton is a dedicated bioinformatician specializing in synthetic biology, and focusing on the design of genetic constructs for mammals and algae. He has developed his expertise in multi-omics analysis and advanced big data techniques, utilizing machine learning to decipher gene expression patterns. As an active member of the open-source community, Anton contributes to numerous bioinformatics projects. His proficiency is especially prominent in the realm of mammalian synthetic biology. Beyond his laboratory endeavors, Anton is a co-founder of the International Longevity Alliance, where he advocates for global advancements in anti-aging technologies."
            },
            {
                "id": 5,
                "icon": EugenPhoto,
                "title": "Eugen Ursu, MD",
                "role": "R&D, Methodology, Troubleshooting",
                "description": "Eugen is a medical doctor, also studying towards his PhD in Computational Immunology. He has gained substantial experience in cell and synthetic biology through extensive wet lab work. Eugen published several papers in the field of longevity and aging research. His research primarily focuses on gene expression analysis, intercellular communication, and antibody-antigen interactions. In CellFabrik's projects, he has developed extensive expertise in both algae and mammalian cell cultures."
            },
            {
                "id": 6,
                "icon": SimonaPhoto,
                "title": "Simona Ghenea, PhD",
                "role": "Senior researcher, Molecular & Synthetic Biology",
                "description": "Simona is a senior researcher at the Institute of Biochemistry of the Romanian Academy, focusing on the degradation of misfolded proteins. She earned her PhD from Hiroshima University, specializing in molecular studies of peroxisome biogenesis and continued with a postdoctoral project at Queen’s University in Kingston, Canada, where her research was centered on the genetics of morphogenesis and cell signaling. With expertise in molecular cell biology, genetics and animal models, she does genetic engineering and synthetic biology, as part of CellFabrik's projects."
            },
            {
                "id": 7,
                "icon": GabiPhoto,
                "title": "Gabriela Chiritoiu, PhD",
                "role": "Senior researcher, Cell Biology & Proteomics",
                "description": "Gabriela is a scientist whose research is focused on protein degradation and antigen processing. She has a PhD in biochemistry and has conducted several academic projects at the Institute of Biochemistry, covering the complex roles of glycosylation in epitope generation and antigen presentation, and deciphering the degradation mechanisms of unfolded proteins associated with the Endoplasmic reticulum, using proteomics (including mass spectrometry and protein analysis) and immunopeptidome analysis. She also has experience with the design, generation and characterization of various tumoral or mesenchymal stem cell lines. At CellFabrik, Gabriela is contributing with her extensive expertise and skills in mammalian cell culture, protein purification and quantitative biochemistry techniques."
            },
            {
                "id": 8,
                "icon": MonicaPhoto,
                "title": "Monica Tudor",
                "role": "Junior researcher, Cell Culturing, Biochemistry",
                "description": "Monica is an emerging talent in the field of biochemistry and molecular biology. She completed her Bachelor's and Master’s degrees in Biochemistry at the Faculty of Biology. Currently, she is conducting research toward her PhD at the Institute of Biochemistry, focusing on the development of small molecule inhibitors for the prevention of ovarian cancer. Monica specializes in 3D cell culture, protein expression in cells and tissues, flow cytometry and small molecules screening."
            }
        ]
    },
    // "Gitcoin": {
    //     "title": "Opensource and non-profit activities"
    // },
    "Advisory": {
        "section_title": "Advisory Board",
        "section_description": "Technical and business support from the best in the field",
        "data":
            {

            }
        ,
    },
    "Contact": {
        "title": "Contact",
        "text": "For any inquiries, please contact us at research@cellfabrik.bio."
    },
    "Footer": {}
}
